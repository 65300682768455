/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from '@material-ui/core';
import { useResponseUser, useTest } from '@orientaction/hooks';
import { LayoutResultat } from '@orientaction/layouts';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useStyles } from './style';

const Felicitations = () => {
  const classes = useStyles();

  const history = useHistory();

  const dataParams: any = useParams();

  const { getTestResultText, resetProgress } = useTest();

  const { getResponseTestUser } = useResponseUser();

  const [text, setText] = useState<any>();

  const getData = async () => {
    if (dataParams.id) {
      const oneResponseTest = await getResponseTestUser(dataParams.id);
      const textResponse = await getTestResultText(oneResponseTest.attributes.test.data.id);
      setText(textResponse);
    }
  };

  useEffect(() => {
    getData();
    resetProgress(100);
  }, []);

  return (
    <LayoutResultat showToplines={false} isGradient={false} isNew2={true}>
      <div className={classes.flexCenter}>
        <Grid container={true} direction="column" justifyContent="center" alignItems="center">
          <Grid item={true} xs={12}>
            <h4 className={classes.title}>{text ? text.attributes.title : '...'}</h4>
          </Grid>

          <Grid
            item={true}
            xs={12}
            container={true}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <div
              className={classes.text}
              dangerouslySetInnerHTML={{ __html: text ? text.attributes.description : '...' }}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Button
              className={classes.btnPrimary}
              onClick={() => history.push(`/resultats/${dataParams.id}`)}>
              <span className={classes.textHeader}>Voir les résultats</span>
            </Button>
          </Grid>
        </Grid>
      </div>
    </LayoutResultat>
  );
};
export default Felicitations;
