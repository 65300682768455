import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  btnPrimary: {
    paddingLeft: 20,
    paddingRight: 20,
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  textHeader: {
    display: 'block',
  },
  title: {
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: 36,
    fontFamily: 'Poppins',
    lineHeight: '50.4px',
    marginTop: 5,
    marginLeft: 25,
    marginRight: 25,
    '@media only screen and (max-width: 600px)': {
      fontSize: 20,
      lineHeight: '35px',
    },
  },
  text: {
    color: '#FFFFFF',
    fontSize: '15px',
    fontWeight: 400,
    fontFamily: 'Poppins !important',
    textAlign: 'left',
    width: '60%',
    marginTop: '25px',
    marginBottom: '25px',
    '@media only screen and (max-width: 600px)': {
      width: '80%',
    },
    '&>p': {
      margin: 0,
    },
    '& a': {
      color: '#fff !important',
    },
    '& li': {
      textAlign: 'left',
    },
    '& *': {
      color: '#fff !important',
    },
  },
  flexCenter: {
    width: '100%',
    minHeight: '80vh',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    position: 'relative',
    marginBottom: 40,
    '@media only screen and (max-width: 600px)': {
      width: '95%',
    },
  },
});
