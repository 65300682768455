import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  takingTestContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  questionTitle: {
    zIndex: 1,
  },
  questionSliderContainer: {
    // flexBasis: '30%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  testTitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    color: '#FFFFFF',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  questionText: {
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  formControlLabel: {
    backgroundColor: 'white',
    marginBottom: 10,
    borderRadius: 10,
    margin: 0,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Poppins',
    '@media only screen and (max-width: 600px)': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 10,
    },
  },
  formControl: {
    margin: 0,
    display: 'block',
  },
  label: {
    color: theme.palette.primary.dark,
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    '&>p': {
      '@media only screen and (max-width: 600px)': {
        margin: 0,
      },
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  button: {
    color: theme.palette.primary.main,
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#fff',
    textTransform: 'none',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
      border: `1px solid white`,
    },
    '&:focus': {
      background: theme.palette.primary.dark,
      color: 'white',
      border: `1px solid white`,
    },
  },
  previousButton: {
    marginTop: 25,
    color: 'white',
    border: '1px solid white',
    textTransform: 'none',
    '@media only screen and (max-width: 600px)': {
      fontSize: '12px',
    },
  },
  bodycenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 8,
    height: '100%',
    overflowY: 'hidden',
  },
  flexCenterMessage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Poppins',
    '& p': {
      margin: 0,
    },
    '@media only screen and (max-width: 600px)': {
      fontSize: '20px',
      marginTop: 10,
      marginBottom: 10,
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 0,
      marginBottom: 5,
    },
  },
  btnPrimary: {
    paddingLeft: 20,
    paddingRight: 20,
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  textHeader: {
    display: 'block',
  },
  noFlesxbasis: {
    [theme.breakpoints.down('sm')]: {
      flexBasis: 0,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 25,
    },
  },
  responseItemsContainer: {
    flexWrap: 'unset',
  },
}));

export default useStyles;
