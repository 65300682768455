/* eslint-disable react/no-danger */
/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useMe, useResponseUser, useTest } from '@orientaction/hooks';
import { getFromLS } from '@orientaction/utils';
import classNames from 'classnames';
import HtmlReactParser from 'html-react-parser';
import { find, groupBy, map, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import DialogNotConnected from './Dialog';
import style from './style';
import { userService } from '@orientaction/services';

const qs = require('qs');

const MinorTestTaking = () => {
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [swiper1, setSwiper] = useState<any>();
  const [textNext, setTextNext] = useState('Question suivante');
  const [valueArray, setValueArray] = useState(['']);
  const [indexArray, setIndexArray] = useState(0);
  const [dataToPost, setDataToPost] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [protectedTag, setProtectedTag] = useState<any>();
  const [openBlock, setOpenBlock] = useState(false);
  const { loading: loadingMe, me } = useMe();

  const { id: testId }: any = useParams();
  const history = useHistory();

  const { getTest, test, setProgress, getResponsesPopulate, loadPage } = useTest();
  const { postResponseUsers, responses } = useResponseUser();

  const classes = style();

  const user = JSON.parse(getFromLS('userInfos') || '{}');
  const token = localStorage.getItem('userToken') ? getFromLS('userToken') : '';

  const getTestQuestions = async () => {
    const filter = qs.stringify(
      {
        populate: {
          questions: {
            populate: {
              responses: {
                populate: ['variable'],
                sort: ['order:asc'],
              },
            },
            sort: ['order:asc'],
          },
        },
      },
      {
        encodeValuesOnly: true,
      },
    );
    const testData = await getTest(testId, filter);
    setOpenBlock(testData?.attributes?.isDesabled || testData?.attributes?.isArchived);
    removeAttr(testData);
  };

  useEffect(() => {
    getTestQuestions();
  }, []);

  const mapDataCalcul = async (array: any) => {
    const searchParams = qs.stringify(
      {
        filters: {
          id: {
            $in: array,
          },
        },
      },
      {
        encodeValuesOnly: true,
      },
    );

    const data: any = await getResponsesPopulate(searchParams);

    const flattenedData = data.data.data.flatMap((item: any) =>
      item.attributes.variable.data.map((variable: any) => ({
        variableKey: variable.attributes.variableKey,
        variableValue: item.attributes.variableValue,
      })),
    );

    const dataGroupByVariableKey = groupBy(flattenedData, 'variableKey');

    const dataMapValue = map(dataGroupByVariableKey, (responsesx: any, key: string) => ({
      somme: sumBy(responses, 'variableValue'),
      key,
    }));

    return {
      ...dataToPost,
      variableA: find(dataMapValue, ['key', 'A'])?.somme || 0,
      variableB: find(dataMapValue, ['key', 'B'])?.somme || 0,
    };
  };

  const postDataAfterCalculSum = async () => {
    console.log('datatopost', dataToPost.responses);
    const newDataToPost = await mapDataCalcul(dataToPost.responses);
    console.log('newDataToPost', newDataToPost);
    const postedResponse = await postResponseUsers(newDataToPost);
    return postedResponse;
  };

  // init swiper for getting swiper data with initial value
  const initSwiper = (swiper: any) => {
    setProgress(
      parseInt(swiper.activeIndex, 10),
      parseInt(test.attributes ? test.attributes.questions.data.length : 0, 10),
    );
    setSwiper(swiper);
  };

  const slideChange = (slide: any) => {
    setProgress(
      parseInt(slide.activeIndex, 10),
      parseInt(test.attributes.questions.data.length, 10),
    );
    setIndexArray(parseInt(slide.activeIndex, 10));
    if (
      parseInt(slide.activeIndex, 10) + 1 ===
      parseInt(test.attributes.questions.data.length, 10)
    ) {
      setTextNext('Voir mes résultats');
    } else {
      setTextNext('Question suivante');
    }
  };

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newArray: any = [...valueArray];
    const val = (event.target as HTMLInputElement).value;
    newArray[indexArray] = val;
    setValueArray(newArray);
  };

  const removeAttr = (testData: any) => {
    const tags: any = document.getElementsByTagName('meta');
    setProtectedTag({
      attributes: {
        title: document.title,
        image: tags[5].content,
        description: tags.description.content,
      },
    });
    document.title = testData.attributes.title;
    tags.description.content = testData.attributes?.description;
    tags[5].content = testData.attributes?.image;
    tags[4].content = testData.attributes?.image;
  };

  // function to go to the next swiper if it's not the end one
  const checkNextSwipe = async () => {
    if (
      valueArray[parseInt(swiper1.activeIndex, 10)] !== '' &&
      valueArray[parseInt(swiper1.activeIndex, 10)]
    ) {
      if (
        parseInt(swiper1.activeIndex, 10) + 1 ===
        parseInt(test.attributes.questions.data.length, 10)
      ) {
        // put here the function to post or store user's response
        setLoading(true);
        const postedResponse = await postDataAfterCalculSum();
        // eslint-disable-next-line no-constant-condition
        if (me) {
          console.log({me})
          await userService.modifyUser({
            token,
            counterTest: me.counterTest ? parseInt(me?.counterTest, 10) + 1 : 1,
            id: user?.id,
          });
        }
        if (!user.id) {
          setOpen(true);
        } else {
          // removeAttr(protectedTag);
          history.push(`/congrats/${postedResponse.id}`);
        }
        setLoading(false);
      } else {
        setQuestionNumber(questionNumber + 1);
        swiper1.slideNext();
      }
    }
  };

  const handlePrevious = () => {
    setQuestionNumber(questionNumber - 1);
    swiper1.slidePrev();
  };

  const redirectLogic = () => {
    removeAttr(protectedTag);
    if (user.id) {
      history.push('/main');
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    const dataMapedToPost = {
      users_permissions_users: [`${user.id}`],
      responses: valueArray,
      test: test.id,
    };
    setDataToPost(dataMapedToPost);
  }, [valueArray, indexArray]);

  const goToSignupPage = () => {
    removeAttr(protectedTag);
    history.push('/signup?from=test');
  };

  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <HelmetProvider>
      {loadPage || loading ? (
        <div className={classes.bodycenter}>
          <CircularProgress style={{ color: 'white' }} />
        </div>
      ) : !openBlock ? (
        <>
          <Grid container={true} style={{ paddingTop: "env(safe-area-inset-top)" }}>
            <Grid item={true} md={2} sm={12}>
              {questionNumber !== 1 && (
                <Button
                  startIcon={<ArrowBack />}
                  variant="outlined"
                  className={classes.previousButton}
                  onClick={handlePrevious}
                >
                  Question précédente
                </Button>
              )}
            </Grid>
            <Grid item={true} xs={12} md={8} sm={12}>
              <Grid className={classes.takingTestContainer} container={true}>
                <Grid className={classes.questionTitle} item={true} sm={12} md={12}>
                  <h1 className={classes.testTitle}>Question {questionNumber}</h1>
                </Grid>

                <Grid className={classes.questionSliderContainer} container={true}>
                  <Grid item={true} xs={12} sm={12} md={12}>
                    <Swiper
                      onSlideChange={slideChange}
                      spaceBetween={50}
                      slidesPerView={1}
                      onSwiper={initSwiper}
                      allowTouchMove={false}
                    >
                      {(test.attributes ? test.attributes.questions.data : []).map(
                        (question: any) => {
                          return (
                            <SwiperSlide key={question.id}>
                              <h1
                                className={classes.questionText}
                                dangerouslySetInnerHTML={{
                                  __html: question?.attributes?.title,
                                }}
                              />
                              <FormControl style={{ width: '100%' }} component="fieldset">
                                <RadioGroup
                                  className={classes.responseItemsContainer}
                                  aria-label="question"
                                  value={valueArray[indexArray]}
                                  onChange={changeValue}
                                >
                                  {(question ? question?.attributes?.responses?.data : []).map(
                                    (response: any) => {
                                      return (
                                        <FormControlLabel
                                          key={response.id}
                                          value={response.id}
                                          className={classes.formControlLabel}
                                          classes={{ label: classes.label }}
                                          checked={
                                            parseInt(valueArray[indexArray], 10) ===
                                            parseInt(response.id, 10)
                                          }
                                          control={
                                            <Radio
                                              color="default"
                                              checkedIcon={
                                                <span
                                                  className={classNames(
                                                    classes.icon,
                                                    classes.checkedIcon,
                                                  )}
                                                />
                                              }
                                              icon={<span className={classes.icon} />}
                                            />
                                          }
                                          label={HtmlReactParser(response.attributes.title)}
                                        />
                                      );
                                    },
                                  )}
                                </RadioGroup>
                              </FormControl>
                              <div className={classes.buttonContainer}>
                                <Button
                                  endIcon={<ArrowForward />}
                                  variant="outlined"
                                  className={classes.button}
                                  onClick={checkNextSwipe}
                                >
                                  {textNext}
                                </Button>
                              </div>
                            </SwiperSlide>
                          );
                        },
                      )}
                    </Swiper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} md={2} sm={12} />
          </Grid>
          <DialogNotConnected
            open={open}
            handleClose={handleClose}
            goToSignupPage={goToSignupPage}
          />
        </>
      ) : (
        <div className={classes.flexCenterMessage}>
          <Grid container={true} direction="column" justifyContent="center" alignItems="center">
            <Grid item={true} xs={12}>
              <h3 className={classes.title} style={{ color: 'white' }}>
                Nous sommes désolés mais ce test n’est plus disponible
              </h3>
            </Grid>
            <Grid item={true} xs={12}>
              <Button className={classes.btnPrimary} onClick={redirectLogic}>
                <span className={classes.textHeader}>Aller vers Orient Action</span>
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </HelmetProvider>
  );
};

export default MinorTestTaking;
